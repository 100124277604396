/* eslint-disable import/consistent-type-specifier-style, import/no-empty-named-blocks -- TODO: de-lint */
export const colors = {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',

  blush: {
    50: 'oklch(97.67% 0.009 341.8)',
    100: 'oklch(95.12% 0.019 343.95)',
    200: 'oklch(92.66% 0.031 345.99)',
    300: 'oklch(90.34% 0.04 345.42)',
    400: 'oklch(82% 0.076 343.13)',
    500: 'oklch(72.59% 0.116 345)',
    600: 'oklch(62.88% 0.157 345.76)',
    700: 'oklch(52.53% 0.149 348.24)',
    800: 'oklch(43.3% 0.141 351.7)',
    900: 'oklch(34.27% 0.131 346.54)',
  },

  citron: {
    50: 'oklch(98.88% 0.022 98.63)',
    100: 'oklch(95.84% 0.042 96.93)',
    200: 'oklch(92.93% 0.063 98.62)',
    300: 'oklch(89.92% 0.083 98.05)',
    400: 'oklch(87.07% 0.102 98.92)',
    500: 'oklch(84.14% 0.118 98.26)',
    600: 'oklch(76.23% 0.159 101.77)',
    700: 'oklch(66.07% 0.136 97.91)',
    800: 'oklch(55.86% 0.114 92.15)',
    900: 'oklch(45.24% 0.093 82.71)',
  },

  gray: {
    50: 'oklch(97.04% 0.001 286.38)',
    100: 'oklch(94.37% 0.003 286.35)',
    200: 'oklch(89.56% 0.005 286.29)',
    300: 'oklch(73.26% 0.016 290.19)',
    400: 'oklch(61.92% 0.022 288.79)',
    500: 'oklch(50.05% 0.03 287.68)',
    600: 'oklch(34.40% 0.026 288)',
    700: 'oklch(28.11% 0.018 285.17)',
    800: 'oklch(24.70% 0.015 285.24)',
    900: 'oklch(21.17% 0.012 285.35)',
  },

  periwinkle: {
    50: 'oklch(97.83% 0.007 295.45)',
    100: 'oklch(92.23% 0.026 288.31)',
    200: 'oklch(86.26% 0.045 286.8)',
    300: 'oklch(80.36% 0.067 286.68)',
    400: 'oklch(74.62% 0.089 285.51)',
    500: 'oklch(68.77% 0.127 283.6)',
    600: 'oklch(63.37% 0.163 281.51)',
    700: 'oklch(53.34% 0.231 275.75)',
    800: 'oklch(42.04% 0.216 278.25)',
    900: 'oklch(31.08% 0.19 276.62)',
  },

  pistachio: {
    50: 'oklch(95.83% 0.022 123.63)',
    100: 'oklch(91.65% 0.044 124.02)',
    200: 'oklch(87.75% 0.069 123.9)',
    300: 'oklch(83.6% 0.091 124.57)',
    400: 'oklch(79.47% 0.113 125.28)',
    500: 'oklch(71.2% 0.176 123.85)',
    600: 'oklch(62.69% 0.156 124.44)',
    700: 'oklch(54.08% 0.137 125.98)',
    800: 'oklch(45.12% 0.118 128.13)',
    900: 'oklch(35.92% 0.096 129.55)',
  },

  ruby: {
    50: 'oklch(94.97% 0.017 17.46)',
    100: 'oklch(89.75% 0.037 17.86)',
    200: 'oklch(84.7% 0.055 18.3)',
    300: 'oklch(79.61% 0.077 18.91)',
    400: 'oklch(74.83% 0.098 19.64)',
    500: 'oklch(65.94% 0.157 24.62)',
    600: 'oklch(56.61% 0.161 25.9)',
    700: 'oklch(46.41% 0.14 26.79)',
    800: 'oklch(36.58% 0.115 28.88)',
    900: 'oklch(27.28% 0.1 30.83)',
  },

  rust: {
    50: 'oklch(98.04% 0.013 63.93)',
    100: 'oklch(92.72% 0.033 64.43)',
    200: 'oklch(87.32% 0.052 65.16)',
    300: 'oklch(81.78% 0.071 63.05)',
    400: 'oklch(76.55% 0.092 62.49)',
    500: 'oklch(72.4% 0.129 60.34)',
    600: 'oklch(67.77% 0.151 56.3)',
    700: 'oklch(60.84% 0.152 52.46)',
    800: 'oklch(53.05% 0.138 51.12)',
    900: 'oklch(41.78% 0.114 47.76)',
  },

  seaglass: {
    50: 'oklch(96.78% 0.011 176.32)',
    100: 'oklch(93.7% 0.025 174.72)',
    200: 'oklch(90.5% 0.036 174.66)',
    300: 'oklch(87.47% 0.05 173.75)',
    400: 'oklch(84.31% 0.061 173.43)',
    500: 'oklch(77.28% 0.094 177.08)',
    600: 'oklch(65.29% 0.095 180.75)',
    700: 'oklch(55.68% 0.082 185.31)',
    800: 'oklch(45.4% 0.066 192.26)',
    900: 'oklch(35.34% 0.053 195.01)',
  },

  shade: {
    50: 'oklch(97.04% 0.001 286.38)',
    100: 'oklch(94.37% 0.003 286.35)',
    200: 'oklch(89.56% 0.005 286.29)',
    300: 'oklch(81.41% 0.011 291.9)',
    400: 'oklch(73.26% 0.016 290.19)',
    500: 'oklch(61.92% 0.022 288.79)',
    600: 'oklch(50.05% 0.03 287.68)',
    700: 'oklch(43.46% 0.026 288.12)',
    800: 'oklch(36.48% 0.021 285.34)',
    900: 'oklch(29.03% 0.018 289.81)',
    950: 'oklch(21.17% 0.012 285.35)',
  },

  sky: {
    50: 'oklch(95.46% 0.011 243.65)',
    100: 'oklch(90.82% 0.023 241.9)',
    200: 'oklch(86.4% 0.033 238.2)',
    300: 'oklch(81.72% 0.046 239.04)',
    400: 'oklch(77.09% 0.057 240.4)',
    500: 'oklch(68.83% 0.09 244.42)',
    600: 'oklch(60.24% 0.089 246.07)',
    700: 'oklch(52.1% 0.087 244.96)',
    800: 'oklch(43.15% 0.088 249.19)',
    900: 'oklch(34.41% 0.086 252.27)',
  },
};
