/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDimensionDto } from '../models/AddDimensionDto';
import type { Dimension } from '../models/Dimension';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DimensionsService {

    /**
     * @returns Dimension
     * @throws ApiError
     */
    public static dimensionControllerGetDimensions(): CancelablePromise<Array<Dimension>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/dimensions',
        });
    }

    /**
     * Create a new dimension
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns Dimension
     * @throws ApiError
     */
    public static dimensionControllerCreateDimension(
        xCcApiKey: string,
        requestBody: AddDimensionDto,
    ): CancelablePromise<Dimension> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/dimensions',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
