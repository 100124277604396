import {
  createMultiStyleConfigHelpers,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { colors } from './colors';

const tabsHelpers = createMultiStyleConfigHelpers(['tab']);
const Tabs = tabsHelpers.defineMultiStyleConfig({
  baseStyle: tabsHelpers.definePartsStyle({
    tab: {
      _selected: {
        color: 'white',
        bg: colors.periwinkle[700],
        fontWeight: 'bold',
        borderRadius: 'full',
      },
    },
  }),
});

/**
 * The Chakra-UI theme for the v2 interface.
 *
 * @see ../../theme.tsx for the v1 theme
 */
const theme = extendTheme(
  // General-purpose theme overrides:
  {
    fonts: {
      heading: 'Gilroy, sans-serif',
      body: 'Inter, sans-serif',
    },
    components: {
      Breadcrumb: {
        baseStyle: {
          container: {
            fontSize: 'sm',
          },
          link: {
            color: 'gray.500',
            fontWeight: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',

            '&[aria-current=page]': {
              color: 'periwinkle.700',
              fontWeight: 'bold',
            },
          },
          separator: {
            color: 'gray.400',
            marginX: '0.5rem',
          },
        },
      },
      Modal: {
        baseStyle: {
          body: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            px: 0,
            py: 0,
          },
          dialog: {
            gap: '24px',
            px: '24px',
            py: '24px',
          },
          footer: {
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '12px',
            justifyContent: 'flex-start',
            px: 0,
            py: 0,
          },
          header: {
            px: 0,
            py: 0,
          },
        },
      },
      Tabs,
      Tooltip: {
        baseStyle: {
          color: 'white',
        },
      },
    },
  },
  // Extensions to correct for Chakra's <Heading>:
  /*
   * Chakra's <Heading> component doesn't let us change the styles based on the
   * `as` tag. The solution is to
   *  * (1) unset the font-family and font-weight settings for the Header
   *        component
   *  * (2) add global CSS for h1-h6 in @/css/base.scss
   */
  {
    // (1)
    components: {
      Heading: {
        baseStyle: {
          fontFamily: null,
          fontWeight: null,
        },
      },
    },
  },

  // Set default color scheme:
  withDefaultColorScheme({ colorScheme: 'periwinkle' }),

  // It's impossible to _remove_ a style from Chakra's global theme. Setting
  // it to null or undefined just causes Chakra to merge in its default value.
  // Thus, there's no way to define these in base.scss and have them carry
  // through as defaults for Chakra.
  {
    styles: {
      global: {
        a: {
          color: 'periwinkle.700',
          textDecoration: 'underline',
        },
      },
    },
  },
);

// Override all Chakra colors with our palette
Object.assign(theme, { colors });

export { theme };
