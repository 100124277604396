import * as v from 'valibot';

/*
 * This file is included in the public bundle.
 * DO NOT include secrets in this module. With very few exceptions, every
 * environment variable in this module. should start with `NEXT_PUBLIC_`.
 */

// When Next bundles the browser code, `process.env.API_URL` will become
// `undefined` since it's a private environment variable.
export const API_URL = v.parse(v.string(), process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL);
export const API_US_URL = v.parse(
  v.string(),
  process.env.API_US_URL ?? process.env.NEXT_PUBLIC_API_URL,
);
export const API_VERSION = v.parse(v.string(), process.env.NEXT_PUBLIC_API_VERSION);

export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
