/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmissionEventMetadataFrame } from './EmissionEventMetadataFrame';
import type { Employee } from './Employee';
import type { EmployeeMetadataFrame } from './EmployeeMetadataFrame';
import type { Organization } from './Organization';
import type { TravelEvent } from './TravelEvent';

export type EmissionEvent = {
    /**
     * uuid
     */
    id?: string;
    /**
     * created at
     */
    createdAt?: string;
    /**
     * updated at
     */
    updatedAt?: string;
    /**
     * description
     */
    deletedAt?: string;
    eventDate: string;
    definitiveness: EmissionEvent.definitiveness;
    status: EmissionEvent.status;
    emissionEventExternalId?: string;
    employee: Employee;
    employeeMetadataFrame: EmployeeMetadataFrame;
    metadataFrames?: Array<EmissionEventMetadataFrame>;
    travelEvent?: TravelEvent;
    replaced_by_event_id?: string;
    replacedByEvent?: EmissionEvent;
    externalCreatedDate: string;
    organization: Organization;
};

export namespace EmissionEvent {

    export enum definitiveness {
        ACTUAL = 'actual',
        PLANNED = 'planned',
    }

    export enum status {
        PENDING = 'pending',
        COMPLETED = 'completed',
        REFUNDED = 'refunded',
        EXCHANGED = 'exchanged',
    }


}

