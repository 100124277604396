/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkdayService {

    /**
     * Get the list of workers as a CSV file from workday
     * @param employeeLookupRangeStartDate The start date to look for the employees
     * @param employeeLookupRangeEndDate The end date to look for the employees
     * @param getEmployeeMetadataKeys The metadata keys to get from the employees
     * @returns string
     * @throws ApiError
     */
    public static workdayControllerGetWorkers(
        employeeLookupRangeStartDate: string,
        employeeLookupRangeEndDate: string,
        getEmployeeMetadataKeys?: Array<string>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/workday/workers',
            query: {
                'employeeLookupRangeStartDate': employeeLookupRangeStartDate,
                'employeeLookupRangeEndDate': employeeLookupRangeEndDate,
                'getEmployeeMetadataKeys': getEmployeeMetadataKeys,
            },
        });
    }

}
