/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterMetricsDTO } from '../models/FilterMetricsDTO';
import type { Scenario } from '../models/Scenario';
import type { SegmentMetric } from '../models/SegmentMetric';
import type { UpdateScenarioMetricAndInsightDto } from '../models/UpdateScenarioMetricAndInsightDto';
import type { UpdateScenarioMetricDto } from '../models/UpdateScenarioMetricDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScenariosService {

    /**
     * @returns Scenario
     * @throws ApiError
     */
    public static scenarioControllerGetAll(): CancelablePromise<Array<Scenario>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/scenarios',
        });
    }

    /**
     * @returns Scenario
     * @throws ApiError
     */
    public static scenarioControllerCreate(): CancelablePromise<Scenario> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/scenarios',
        });
    }

    /**
     * @param id
     * @returns Scenario
     * @throws ApiError
     */
    public static scenarioControllerGetById(
        id: string,
    ): CancelablePromise<Scenario> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/scenarios/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static scenarioControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/scenarios/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a metric; DEPRECATED
     * @param id
     * @param requestBody
     * @returns SegmentMetric
     * @throws ApiError
     */
    public static scenarioControllerUpdateMetric(
        id: string,
        requestBody: UpdateScenarioMetricDto,
    ): CancelablePromise<SegmentMetric> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/scenarios/{id}/metric',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns SegmentMetric
     * @throws ApiError
     */
    public static scenarioControllerUpdateMetricAndInsight(
        id: string,
        requestBody: UpdateScenarioMetricAndInsightDto,
    ): CancelablePromise<SegmentMetric> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/scenarios/{id}/metric-and-insight',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param dateFrom
     * @param dateTo
     * @param dimensionName
     * @param filter
     * @returns SegmentMetric
     * @throws ApiError
     */
    public static scenarioControllerGetMetrics(
        dateFrom: string,
        dateTo: string,
        dimensionName: string,
        filter?: FilterMetricsDTO,
    ): CancelablePromise<Array<SegmentMetric>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/scenarios/metrics',
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'dimensionName': dimensionName,
                'filter': filter,
            },
        });
    }

}
