/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentAccessRule } from '../models/ContentAccessRule';
import type { ContentAccessRuleSet } from '../models/ContentAccessRuleSet';
import type { CreateContentAccessRuleDTO } from '../models/CreateContentAccessRuleDTO';
import type { CreateContentAccessRuleSetDTO } from '../models/CreateContentAccessRuleSetDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @returns string
     * @throws ApiError
     */
    public static appControllerGetHello(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * This endpoint is used for internal and external health-checks. If it doesn't return a 200, the container will fail to deploy / removed from the ALB
     * @returns any
     * @throws ApiError
     */
    public static appControllerHealthCheck(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/_health',
        });
    }

    /**
     * Login or signup using an Auth0 JWT token
     * @returns boolean
     * @throws ApiError
     */
    public static authControllerTokenLogin(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/login/token',
        });
    }

    /**
     * Auth0 rules check to see if a user was invited
     * @param email The email of the invited user
     * @param orgId The organization id
     * @returns any
     * @throws ApiError
     */
    public static authControllerGetValidInvitation(
        email: string,
        orgId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/auth/invitations',
            query: {
                'email': email,
                'orgId': orgId,
            },
        });
    }

    /**
     * @returns ContentAccessRuleSet
     * @throws ApiError
     */
    public static contentAccessControllerGetContentAccessRuleSets(): CancelablePromise<Array<ContentAccessRuleSet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/content-access/rule-set',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns ContentAccessRuleSet
     * @throws ApiError
     */
    public static contentAccessControllerCreateContentAccessRuleSet(
        xCcApiKey: string,
        requestBody: CreateContentAccessRuleSetDTO,
    ): CancelablePromise<ContentAccessRuleSet> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/content-access/rule-set',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ruleSetId
     * @param xCcApiKey Authorization key to authenticate controller
     * @returns ContentAccessRuleSet
     * @throws ApiError
     */
    public static contentAccessControllerGetContentAccessRuleSet(
        ruleSetId: string,
        xCcApiKey: string,
    ): CancelablePromise<ContentAccessRuleSet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/content-access/rule-set/{ruleSetId}',
            path: {
                'ruleSetId': ruleSetId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
        });
    }

    /**
     * @param ruleSetId
     * @param xCcApiKey Authorization key to authenticate controller
     * @returns void
     * @throws ApiError
     */
    public static contentAccessControllerDeleteContentAccessRuleSet(
        ruleSetId: string,
        xCcApiKey: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/content-access/rule-set/{ruleSetId}',
            path: {
                'ruleSetId': ruleSetId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
        });
    }

    /**
     * @param ruleSetId
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns ContentAccessRule
     * @throws ApiError
     */
    public static contentAccessControllerAddContentAccessRuleToSet(
        ruleSetId: string,
        xCcApiKey: string,
        requestBody: CreateContentAccessRuleDTO,
    ): CancelablePromise<ContentAccessRule> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/content-access/rule-set/{ruleSetId}/rule',
            path: {
                'ruleSetId': ruleSetId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
