/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { DeleteUserDto } from '../models/DeleteUserDto';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static adminControllerCreateUser(
        requestBody: CreateUserDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/admin/user/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return a list of users by organization id
     * @param returnDeletedUsers Return all users including those with a 'deletedAt' value
     * @param returnFieldAsArrayOfStrings Return a single property of the users such as id, name
     * @param overrideOrgId Super User Org ID override
     * @returns User
     * @throws ApiError
     */
    public static adminControllerListUsers(
        returnDeletedUsers?: boolean,
        returnFieldAsArrayOfStrings?: string,
        overrideOrgId?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/admin/users',
            headers: {
                'override-org-id': overrideOrgId,
            },
            query: {
                'returnDeletedUsers': returnDeletedUsers,
                'returnFieldAsArrayOfStrings': returnFieldAsArrayOfStrings,
            },
        });
    }

    /**
     * Hard or soft delete set of users
     * @param requestBody
     * @param deleteFromAuth0 Delete the users from Auth0 (permanent and irreversible)
     * @param softDelete Only soft delete the users
     * @returns any
     * @throws ApiError
     */
    public static adminControllerDeleteUser(
        requestBody: DeleteUserDto,
        deleteFromAuth0: boolean = false,
        softDelete: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/admin/users',
            query: {
                'deleteFromAuth0': deleteFromAuth0,
                'softDelete': softDelete,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Upload a CSV file of users to create
     * @param xCcApiKey Authorization key to authenticate controller
     * @returns any
     * @throws ApiError
     */
    public static adminControllerUploadUsers(
        xCcApiKey: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/admin/users/create',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
        });
    }

    /**
     * Upload a CSV file of users to invite
     * @param xCcApiKey Authorization key to authenticate controller
     * @returns any
     * @throws ApiError
     */
    public static adminControllerInviteUsers(
        xCcApiKey: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/admin/users/invite',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
        });
    }

}
