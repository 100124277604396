/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Employee } from './Employee';

export type FlatEmissionEventDto = {
    id: string;
    eventDate: string;
    definitiveness: FlatEmissionEventDto.definitiveness;
    employee: Employee;
    seatClass: FlatEmissionEventDto.seatClass;
    emissions: string;
    originAirportCode?: string;
};

export namespace FlatEmissionEventDto {

    export enum definitiveness {
        ACTUAL = 'actual',
        PLANNED = 'planned',
    }

    export enum seatClass {
        ECONOMY = 'Economy',
        BUSINESS = 'Business',
        PREMIUM = 'Premium',
        FIRST = 'First',
        PREMIUM_ECONOMY = 'Premium Economy',
    }


}

