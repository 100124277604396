/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMemberDTO } from '../models/AddMemberDTO';
import type { AddUserGroupDto } from '../models/AddUserGroupDto';
import type { UserGroup } from '../models/UserGroup';
import type { UserGroupUser } from '../models/UserGroupUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * List all user groups within the current organization
     * @returns UserGroup
     * @throws ApiError
     */
    public static userGroupsControllerGetAll(): CancelablePromise<Array<UserGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/auth/user-groups',
        });
    }

    /**
     * Add a new user group
     * @param requestBody
     * @returns UserGroup
     * @throws ApiError
     */
    public static userGroupsControllerCreate(
        requestBody: AddUserGroupDto,
    ): CancelablePromise<UserGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/auth/user-groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a user group by ID
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userGroupsControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/auth/user-groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Add a member to a group by user ID
     * @param groupId
     * @param requestBody
     * @returns UserGroupUser
     * @throws ApiError
     */
    public static userGroupsControllerAddMember(
        groupId: string,
        requestBody: AddMemberDTO,
    ): CancelablePromise<UserGroupUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/auth/user-groups/{groupId}/members',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove a member from a group by user ID
     * @param groupId
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static userGroupsControllerRemoveMember(
        groupId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/auth/user-groups/{groupId}/members/{userId}',
            path: {
                'groupId': groupId,
                'userId': userId,
            },
        });
    }

}
