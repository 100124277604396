/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSegmentMetricDto = {
    metricValue: number;
    snapshottedAt: string;
    timeframeStart: string;
    timeframeEnd: string;
    metricType: CreateSegmentMetricDto.metricType;
    dimensionId?: string;
};

export namespace CreateSegmentMetricDto {

    export enum metricType {
        ACTUAL = 'actual',
        PLANNED = 'planned',
        BUDGET = 'budget',
        SCENARIO = 'scenario',
    }


}

