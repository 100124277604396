/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FlatSegmentMetricInsightDto } from './FlatSegmentMetricInsightDto';

export type FlatComparedSegmentMetricAndInsightsDto = {
    timeframeStart: string;
    timeframeEnd: string;
    segmentId: string;
    metricType: FlatComparedSegmentMetricAndInsightsDto.metricType;
    metricValue: number;
    insights: Array<FlatSegmentMetricInsightDto>;
};

export namespace FlatComparedSegmentMetricAndInsightsDto {

    export enum metricType {
        ACTUAL = 'actual',
        PLANNED = 'planned',
        BUDGET = 'budget',
        SCENARIO = 'scenario',
    }


}

