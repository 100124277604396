/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @deprecated
     * Retrieves all users in the database by optional given orgId (sudo only)
     * @param overrideOrgId Super User Org ID override
     * @returns User
     * @throws ApiError
     */
    public static usersControllerFindAll(
        overrideOrgId?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users',
            headers: {
                'override-org-id': overrideOrgId,
            },
        });
    }

    /**
     * Retrieves the authorized user
     * @returns User
     * @throws ApiError
     */
    public static usersControllerMyProfile(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me',
        });
    }

    /**
     * Retrieves a user
     * @param id
     * @returns User
     * @throws ApiError
     */
    public static usersControllerFindOne(
        id: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * updates a user
     * @param id
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static usersControllerUpdateUser(
        id: string,
        requestBody: UpdateUserDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * updates a user
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static usersControllerUpdateUserDetails(
        requestBody: UpdateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/updateUserDetails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
