/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteUserDto } from '../models/InviteUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsInternalService {

    /**
     * Invite a user to an existing organization and attach roles. The user will get an invitation email
     * @param xCcApiKey Authorization key to authenticate controller
     * @param id
     * @param requestBody
     * @returns any The Invitation link and meta data
     * @throws ApiError
     */
    public static organizationsInternalControllerAddUser(
        xCcApiKey: string,
        id: string,
        requestBody: InviteUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations-internal/{id}/user',
            path: {
                'id': id,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve the correct role from auth0 for bulk invites
     * @param xCcApiKey Authorization key to authenticate controller
     * @returns string
     * @throws ApiError
     */
    public static organizationsInternalControllerGetInviteeRole(
        xCcApiKey: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations-internal/invitee/role',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
        });
    }

}
