/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dimension } from './Dimension';
import type { Organization } from './Organization';
import type { Scenario } from './Scenario';
import type { Segment } from './Segment';
import type { SegmentMetricInsight } from './SegmentMetricInsight';

export type SegmentMetric = {
    /**
     * uuid
     */
    id?: string;
    /**
     * created at
     */
    createdAt?: string;
    /**
     * updated at
     */
    updatedAt?: string;
    /**
     * description
     */
    deletedAt?: string;
    metricValue: number;
    snapshottedAt: string;
    timeframeStart: string;
    timeframeEnd: string;
    metricType: SegmentMetric.metricType;
    isProjection?: boolean;
    isBudget?: boolean;
    segment: Segment;
    segmentId: string;
    organization: Organization;
    organizationId: string;
    scenario?: Scenario;
    scenarioId?: string;
    insights?: Array<SegmentMetricInsight>;
    dimension: Dimension;
    dimensionId: string;
};

export namespace SegmentMetric {

    export enum metricType {
        ACTUAL = 'actual',
        PLANNED = 'planned',
        BUDGET = 'budget',
        SCENARIO = 'scenario',
    }


}

