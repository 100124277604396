/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmissionEventsResponse } from '../models/EmissionEventsResponse';
import type { FilterEventsDTO } from '../models/FilterEventsDTO';
import type { SortEventsDTO } from '../models/SortEventsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmissionEventsService {

    /**
     * @param dateFrom
     * @param dateTo
     * @param filter
     * @param limit
     * @param sort
     * @returns EmissionEventsResponse
     * @throws ApiError
     */
    public static emissionEventControllerGetEmissionEvents(
        dateFrom?: string,
        dateTo?: string,
        filter?: FilterEventsDTO,
        limit?: number,
        sort?: SortEventsDTO,
    ): CancelablePromise<EmissionEventsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/emission-events',
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'filter': filter,
                'limit': limit,
                'sort': sort,
            },
        });
    }

}
