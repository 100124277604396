/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNotificationDto } from '../models/CreateNotificationDto';
import type { Notification } from '../models/Notification';
import type { UpdateNotificationDto } from '../models/UpdateNotificationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * @param requestBody
     * @returns Notification
     * @throws ApiError
     */
    public static notificationsControllerCreate(
        requestBody: CreateNotificationDto,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UpdateNotificationDto
     * @throws ApiError
     */
    public static notificationsControllerFindAll(): CancelablePromise<Array<UpdateNotificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/notifications',
        });
    }

    /**
     * @param id
     * @returns UpdateNotificationDto
     * @throws ApiError
     */
    public static notificationsControllerFindOne(
        id: string,
    ): CancelablePromise<UpdateNotificationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/notifications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static notificationsControllerUpdate(
        id: string,
        requestBody: UpdateNotificationDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/notifications/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static notificationsControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/notifications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static notificationsControllerDismiss(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/notifications/dismiss/{id}',
            path: {
                'id': id,
            },
        });
    }

}
