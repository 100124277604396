/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FlightsService {

    /**
     * Upload a CSV file of flights with emissions
     * @param overrideOrgId Super User Org ID override
     * @returns any
     * @throws ApiError
     */
    public static flightControllerUploadFlightEmissions(
        overrideOrgId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/flights/emissions',
            headers: {
                'override-org-id': overrideOrgId,
            },
        });
    }

    /**
     * Get emissions per flight
     * @param seatClass
     * @param originAirportCode
     * @param destinationAirportCode
     * @param useDefaultFlightIfNotFound
     * @param isRoundTrip
     * @returns number
     * @throws ApiError
     */
    public static flightControllerGetFlightEmissions(
        seatClass: 'Economy' | 'Business' | 'Premium' | 'First' | 'Premium Economy',
        originAirportCode: string,
        destinationAirportCode: string,
        useDefaultFlightIfNotFound?: boolean,
        isRoundTrip?: boolean,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/flights/emissions',
            query: {
                'seatClass': seatClass,
                'originAirportCode': originAirportCode,
                'destinationAirportCode': destinationAirportCode,
                'useDefaultFlightIfNotFound': useDefaultFlightIfNotFound,
                'isRoundTrip': isRoundTrip,
            },
        });
    }

    /**
     * Upload a CSV file of flights
     * @param xCcApiKey Authorization key to authenticate controller
     * @param employeeLookupRangeStartDate The start date to look for the employees
     * @param employeeLookupRangeEndDate The end date to look for the employees
     * @param organizationId
     * @param flightsStartDate The start date for the flights to be ingested, inclusive.
     * @param flightsEndDate The end date for the flights to be ingested, inclusive.
     * @param getEmployeeMetadataKeys The metadata keys to get from the employees
     * @param ignoreFlightsWithoutEmissions If true, flights without emissions will be ignored. Default is true.
     * @returns any
     * @throws ApiError
     */
    public static dataPipelineFlightsControllerUploadFlights(
        xCcApiKey: string,
        employeeLookupRangeStartDate: string,
        employeeLookupRangeEndDate: string,
        organizationId: string,
        flightsStartDate: string,
        flightsEndDate: string,
        getEmployeeMetadataKeys?: Array<string>,
        ignoreFlightsWithoutEmissions: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/data-pipeline/flights',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            query: {
                'employeeLookupRangeStartDate': employeeLookupRangeStartDate,
                'employeeLookupRangeEndDate': employeeLookupRangeEndDate,
                'getEmployeeMetadataKeys': getEmployeeMetadataKeys,
                'organizationId': organizationId,
                'flightsStartDate': flightsStartDate,
                'flightsEndDate': flightsEndDate,
                'ignoreFlightsWithoutEmissions': ignoreFlightsWithoutEmissions,
            },
        });
    }

}
