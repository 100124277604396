/* eslint-disable @typescript-eslint/no-unsafe-assignment -- TODO: fix types */
/* eslint-disable import/consistent-type-specifier-style, import/no-empty-named-blocks, import/order -- TODO: de-lint */
import { PRIMARY_PERIWINKLE, SECONDARY_LIGHT_PERIWINKLE, WHITE } from '@/lib/colors';
import {
  Box,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CUSTOM_MEDIA_QUERIES } from '@/constants';

export interface ButtonProps extends ChakraButtonProps {
  label?: string;
  onClick?: () => void;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  isFullWidth?: boolean;
  bgColor?: string;
  disable?: boolean;
  labelColor?: string;
  textFontWeight?: string;
  borderColorProp?: string;
  labelColorProps?: string;
  backgroundColorProp?: string;
  paddingRightProp?: string;
  paddingLeftProp?: string;
  className?: string;
}

export const Button = ({
  label,
  leftIcon,
  rightIcon,
  onClick,
  size = 'md',
  isFullWidth = false,
  variant = 'solid',
  disable = false,
  textFontWeight,
  borderColorProp,
  labelColorProps,
  backgroundColorProp,
  paddingRightProp,
  paddingLeftProp,
  className,
  ...props
}: ButtonProps) => {
  const [isMobileViewOpen] = useMediaQuery(CUSTOM_MEDIA_QUERIES.MOBILE);
  //TODO: This should not be in the generic component here.
  // Calling component should have control on what to pass
  let backgroundColor;
  let borderColor;
  if (variant !== 'link') {
    backgroundColor = variant === 'solid' ? PRIMARY_PERIWINKLE : WHITE;
    borderColor = variant === 'outline' ? PRIMARY_PERIWINKLE : WHITE;
  }

  const initLabelColor = variant === 'solid' ? WHITE : PRIMARY_PERIWINKLE;
  const [labelColor, setLabelColor] = useState(initLabelColor);

  useEffect(() => {
    setLabelColor(initLabelColor);
  }, [initLabelColor]);

  const getButtonHoverState = () => {
    if (variant === 'solid') {
      return { bg: SECONDARY_LIGHT_PERIWINKLE };
    } else {
      return { borderColor: SECONDARY_LIGHT_PERIWINKLE };
    }
  };
  const onButtonHover = () => {
    if (variant === 'solid') return;
    const textColor = variant === 'outline' ? SECONDARY_LIGHT_PERIWINKLE : PRIMARY_PERIWINKLE;
    setLabelColor(textColor);
  };
  const onButtonLeave = () => {
    setLabelColor(labelColorProps || initLabelColor);
  };
  return (
    <ChakraButton
      {...props}
      bgColor={backgroundColorProp || backgroundColor}
      fontWeight={'semibold'}
      leftIcon={leftIcon ? leftIcon : <Box />}
      rightIcon={rightIcon ? rightIcon : <Box />}
      onClick={onClick}
      borderRadius="xl"
      px={3}
      borderColor={borderColorProp || borderColor}
      variant={variant}
      size={size}
      justifyContent={'space-between'}
      disabled={disable}
      _hover={
        isMobileViewOpen
          ? { boxShadow: 'none', background: backgroundColor }
          : getButtonHoverState()
      }
      _focus={{ boxShadow: 'none' }}
      onMouseEnter={onButtonHover}
      onMouseLeave={onButtonLeave}
      className={className}
      width={isFullWidth ? 'full' : null}
    >
      <Text
        align={'center'}
        pl={paddingLeftProp || 5}
        pr={paddingRightProp || (rightIcon ? 3 : 5)}
        color={labelColorProps || labelColor}
        fontWeight={textFontWeight || 'bold'}
      >
        {label}
      </Text>
    </ChakraButton>
  );
};
