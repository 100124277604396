/*
 * These colors are deprecated. Prefer Chakra-UI color names
 * like `periwinkle.400` or semantic names like `error`.
 *
 * See ./src/theme/colors.ts
 * See https://chakra-ui.com/docs/styled-system/semantic-tokens
 */

export const GRAY = '#E7E6E8';
export const LIGHT_GRAY = '#f4f4f5';
export const MID_DARK_GRAY_1 = '#E2E8F0';
export const MID_DARK_GRAY = '#7B8A8F';
export const DARK_GRAY = '#3F3F3F';
export const DARK_GRAY_2 = '#48484A';
export const DARK_GRAY_3 = '#514F64';
export const DARK_GRAY_4 = '#1A1A1D';
export const DARK_GRAY_5 = '#504f64';
export const DARK_GRAY_6 = '#76767d';
export const DARK_BROWNISH_GRAY = '#767677';
export const LIGHT_BROWNISH_GRAY = '#A3A3A5';
export const GREEN = '#5DACA3';
export const DARK_GREEN = '#1D3D42';
export const WHITE = '#FFFFFF';
export const COLD_BLUE = '#2086E4';
export const BLUE_BACK = '#F3F7F8';
export const PRIMARY_COLOR = '#499FB3';
export const FOOTER_COLOR = '#e6d5f2';
export const PURPLE = '#514FEE';
export const ORANGE_RED = '#ee5f4f';
export const WHITE_GRAY = '#F9F9F9';
export const MAGNOLIA = '#F8F7FC';
export const YELLOW = '#FEE34E';
export const SECONDARY_SHADE = '#504f64';
export const GHOST_COLOR = '#c6c8cf';
export const BORDER_GRAY = '#e3e3e4';
export const MAIN_GREEN = '#B2E061';
export const LIGHT_GREEN = '#94E9CF';
export const LIGHT_ORANGE = '#FAA558';
export const LIGHT_DANGER = '#FC7E7E';
export const LIGHT_BLUE = '#82BDE8';
export const LIGHT_PERIWINKLE = '#CBCBF8';
export const LIGHT_PINK = '#FDCCE5';
export const MOCCACCINO = '#573232';
export const DARK_CHARCOAL = '#333';
export const VIOLET = '#A7A5E3';
export const SUBTITLE_GRAY = '#626174';
export const SUBTITLE_GRAY_PLUS_100 = '#858493';
export const SUBTITLE_GRAY_PLUS_300 = '#DCDCE0';
export const PERIWINKLE = '#9795F5';
export const PROGRESS_BG = '#DCDCE0';
export const BAR_PRIMARY_COLOUR = '#94BAD6';
export const TABLE_RED = '#F65B5B';
export const TABLE_GREEN = '#59956D';
export const MID_GREEN = '#00C853';
export const MID_ORANGE = '#FFA000';
export const MID_RED = '#F4511E';

export const CORAL_24 = 'oklch(74.83% 0.098 19.64 / 0.24)';
export const PISTACHIO_24 = 'oklch(79.47% 0.113 125.28 / 0.24)';

/**
 * These color values represents the color hierarchy
 * used in theme.tsx
 */
export const PRIMARY_PERIWINKLE = 'brand.primary.periwinkle';
export const PRIMARY_SEAFOAM = 'brand.primary.seafoam';
export const PRIMARY_BLACK = 'brand.primary.black';
export const SECONDARY_DARK_GRAY = 'brand.secondary.dark_gray';
export const SECONDARY_MED_GRAY = 'brand.secondary.med_gray';
export const SECONDARY_GRAY = 'brand.secondary.gray';
export const SECONDARY_LIGHT_GRAY = 'brand.secondary.light_gray';
export const SECONDARY_LIGHT_WHITE_GRAY = 'brand.secondary.light_white_gray';
export const SECONDARY_OFF_WHITE = 'brand.secondary.off_white';
export const SECONDARY_RED = 'brand.secondary.red';
export const SECONDARY_YELLOW = 'brand.secondary.yellow';
export const SECONDARY_GREEN = 'brand.secondary.green';
export const SECONDARY_LIGHT_PERIWINKLE = 'brand.secondary.light_periwinkle';
export const HEADING_BLACK = 'brand.secondary.heading_black';
export const BACKGROUND_GRADIENT = 'linear-gradient(270deg, #514FEE 0%, #A8FFE5 100%);';
