/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataPipelineService {

    /**
     * Upserts emission event
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static dataPipelineEmissionEventControllerUploadEmployee(
        xCcApiKey: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/data-pipeline/emission-event',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Upload a CSV file of flights
     * @param xCcApiKey Authorization key to authenticate controller
     * @param employeeLookupRangeStartDate The start date to look for the employees
     * @param employeeLookupRangeEndDate The end date to look for the employees
     * @param organizationId
     * @param flightsStartDate The start date for the flights to be ingested, inclusive.
     * @param flightsEndDate The end date for the flights to be ingested, inclusive.
     * @param getEmployeeMetadataKeys The metadata keys to get from the employees
     * @param ignoreFlightsWithoutEmissions If true, flights without emissions will be ignored. Default is true.
     * @returns any
     * @throws ApiError
     */
    public static dataPipelineFlightsControllerUploadFlights(
        xCcApiKey: string,
        employeeLookupRangeStartDate: string,
        employeeLookupRangeEndDate: string,
        organizationId: string,
        flightsStartDate: string,
        flightsEndDate: string,
        getEmployeeMetadataKeys?: Array<string>,
        ignoreFlightsWithoutEmissions: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/data-pipeline/flights',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            query: {
                'employeeLookupRangeStartDate': employeeLookupRangeStartDate,
                'employeeLookupRangeEndDate': employeeLookupRangeEndDate,
                'getEmployeeMetadataKeys': getEmployeeMetadataKeys,
                'organizationId': organizationId,
                'flightsStartDate': flightsStartDate,
                'flightsEndDate': flightsEndDate,
                'ignoreFlightsWithoutEmissions': ignoreFlightsWithoutEmissions,
            },
        });
    }

}
