/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobGroupStatusDto } from '../models/JobGroupStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobGroupsService {

    /**
     * @param jobGroupId
     * @returns JobGroupStatusDto
     * @throws ApiError
     */
    public static jobGroupV2ControllerGetJobGroupStatus(
        jobGroupId: string,
    ): CancelablePromise<JobGroupStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/job-groups/{jobGroupId}/status',
            path: {
                'jobGroupId': jobGroupId,
            },
        });
    }

}
