export const isProductionEnv = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

/**
 * "preview" on NEXT_PUBLIC_VERCEL_ENV is staging and preview
 * "development" on NEXT_PUBLIC_VERCEL_ENV is development only therefore we need an additional check for git commit ref
 */
export const isStagingEnv =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' &&
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging';

export const isTestEnv =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' &&
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'main';

export enum CUSTOM_MEDIA_QUERIES {
  MOBILE = '(max-width:475px)',
  TABLET = '(max-width: 968px)',
  SMALL_DESKTOP = '(max-width: 1385px)',
}
