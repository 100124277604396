import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button } from '../Button';
import styles from './ErrorBoundary.module.scss';
import { PRIMARY_COLOR } from '@/lib/colors';
import { isProductionEnv } from '@/constants';

interface AEFProps extends FallbackProps {
  error: Error & { status?: number };
  title?: string;
  description?: string;
  errorInfo?: React.ErrorInfo | null;
}

const sliceErrorStack = (stackTrace = '', numLines = 10) => {
  const lines = stackTrace.split('\n');
  const firstNLines = lines.slice(0, numLines);
  return firstNLines.join('\n');
};

/**
 * Component to handle client-side errors
 * @see https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors
 */
export const AppErrorFallback = ({ error, title, description }: AEFProps) => {
  const getTitle = () => {
    return title || error?.status || 'Oops';
  };

  const getDescription = () => {
    return description || error?.message || 'Something went wrong!';
  };

  const errorTitle = getTitle();
  const errorDescription = getDescription();

  return (
    <Box
      className={styles.container}
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      <Text fontSize={'6xl'} fontWeight={'bold'}>
        {errorTitle}
      </Text>

      <Box paddingTop={3}>
        <Text fontSize={'md'}>{errorDescription}</Text>
      </Box>

      <Box paddingTop={8}>
        <Button
          size={'lg'}
          bgColor={PRIMARY_COLOR}
          label="Back to Home"
          onClick={() => {
            // have to do a hard refresh here because React errors still exist in its internal state
            window.location.href = '/';
          }}
        />
      </Box>

      {!isProductionEnv && (
        <Box marginTop={20} className={styles.ErrorBoundary}>
          <pre>{error?.message}</pre>
          <details>
            <summary>Expand to Show Error Stack Traces</summary>
            <h5>Stack Trace</h5>
            <pre>{sliceErrorStack(error?.stack)}</pre>
          </details>
        </Box>
      )}
    </Box>
  );
};
