/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckConcurIntegrationDto } from '../models/CheckConcurIntegrationDto';
import type { CreateConcurIntegrationDto } from '../models/CreateConcurIntegrationDto';
import type { GetConcurExpenseDataDto } from '../models/GetConcurExpenseDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConcurIntegrationService {

    /**
     * Delete an existing concur integration by the concur connection id
     * @param concurConnectionId
     * @returns any
     * @throws ApiError
     */
    public static concurIntegrationControllerDeleteConcurIntegration(
        concurConnectionId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/concur-integration/{concurConnectionId}',
            path: {
                'concurConnectionId': concurConnectionId,
            },
        });
    }

    /**
     * Add a concur integration
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static concurIntegrationControllerAddConcurIntegration(
        requestBody: CreateConcurIntegrationDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/concur-integration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static concurIntegrationControllerGetAndStoreConcurExpenseData(
        xCcApiKey: string,
        requestBody: GetConcurExpenseDataDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/concur-integration/expenses',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static concurIntegrationControllerCheckAndRemoveIntegration(
        xCcApiKey: string,
        requestBody: CheckConcurIntegrationDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/concur-integration/integration-health',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
