/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmissionEventDefinitionDto } from '../models/AddEmissionEventDefinitionDto';
import type { AddEmployeeDefinitionDto } from '../models/AddEmployeeDefinitionDto';
import type { AddSegmentTravelEventDefinitionDto } from '../models/AddSegmentTravelEventDefinitionDto';
import type { CreateSegmentDto } from '../models/CreateSegmentDto';
import type { CreateSegmentMetricDto } from '../models/CreateSegmentMetricDto';
import type { CreateTravelEventDto } from '../models/CreateTravelEventDto';
import type { Employee } from '../models/Employee';
import type { FilterMetricsDTO } from '../models/FilterMetricsDTO';
import type { FlatChildrenComparedSegmentMetricAndInsightsDto } from '../models/FlatChildrenComparedSegmentMetricAndInsightsDto';
import type { FlatChildrenSegmentMetricAndInsightsDto } from '../models/FlatChildrenSegmentMetricAndInsightsDto';
import type { FlatComparedSegmentMetricAndInsightsDto } from '../models/FlatComparedSegmentMetricAndInsightsDto';
import type { FlatSegmentMetricDto } from '../models/FlatSegmentMetricDto';
import type { FlatTravelEventDto } from '../models/FlatTravelEventDto';
import type { JobGroupIdDto } from '../models/JobGroupIdDto';
import type { PaginatedResponseDto } from '../models/PaginatedResponseDto';
import type { RecalculateAllSegmentsDto } from '../models/RecalculateAllSegmentsDto';
import type { RecalculateSegmentDto } from '../models/RecalculateSegmentDto';
import type { Segment } from '../models/Segment';
import type { SegmentEmissionEventDefinition } from '../models/SegmentEmissionEventDefinition';
import type { SegmentEmployeeDefinition } from '../models/SegmentEmployeeDefinition';
import type { SegmentMetric } from '../models/SegmentMetric';
import type { SegmentMetricInsight } from '../models/SegmentMetricInsight';
import type { SegmentNode } from '../models/SegmentNode';
import type { SegmentTravelEventDefinition } from '../models/SegmentTravelEventDefinition';
import type { StatusSummaryDto } from '../models/StatusSummaryDto';
import type { TeamPerformanceDto } from '../models/TeamPerformanceDto';
import type { UpdateSegmentStatusDto } from '../models/UpdateSegmentStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SegmentsService {

    /**
     * @param filterParentSegment Filter for identifying top level segments, i.e. when parent segment is null
     * @param page Page number (starting from 1)
     * @param limit Number of records per page
     * @param search Multicolumn search term
     * @param searchBy Limit columns to which apply 'search' term
     * @param sortBy Format: _field_:_direction_ [direction may be ASC or DESC] e.g. id:DESC
     * @returns any
     * @throws ApiError
     */
    public static segmentControllerGetSegments(
        filterParentSegment?: string,
        page?: any,
        limit?: any,
        search?: any,
        searchBy?: Array<string>,
        sortBy?: any,
    ): CancelablePromise<(PaginatedResponseDto & {
        data: Array<Segment>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments',
            query: {
                'filter.parentSegment': filterParentSegment,
                'page': page,
                'limit': limit,
                'search': search,
                'searchBy': searchBy,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns Segment
     * @throws ApiError
     */
    public static segmentControllerCreateSegment(
        xCcApiKey: string,
        requestBody: CreateSegmentDto,
    ): CancelablePromise<Array<Segment>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns Segment
     * @throws ApiError
     */
    public static segmentControllerCreateSegments(
        xCcApiKey: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<Segment>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/batch',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @returns Segment
     * @throws ApiError
     */
    public static segmentControllerGetSegment(
        segmentId: string,
    ): CancelablePromise<Segment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}',
            path: {
                'segmentId': segmentId,
            },
        });
    }

    /**
     * @param sort
     * @returns SegmentNode
     * @throws ApiError
     */
    public static segmentControllerGetSegmentFullGraph(
        sort?: Array<string>,
    ): CancelablePromise<SegmentNode> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/full-graph',
            query: {
                'sort': sort,
            },
        });
    }

    /**
     * @param segmentId
     * @returns Segment
     * @throws ApiError
     */
    public static segmentControllerGetSegmentHierarchy(
        segmentId: string,
    ): CancelablePromise<Array<Segment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/hierarchy',
            path: {
                'segmentId': segmentId,
            },
        });
    }

    /**
     * @param segmentId
     * @param dateFrom
     * @param dateTo
     * @returns FlatSegmentMetricDto
     * @throws ApiError
     */
    public static segmentControllerGetMetricsForChildrenSegments(
        segmentId: string,
        dateFrom?: string,
        dateTo?: string,
    ): CancelablePromise<Array<FlatSegmentMetricDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/children/metrics',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param segmentId
     * @param dimensionId
     * @param requestBody
     * @returns SegmentMetric
     * @throws ApiError
     */
    public static segmentControllerCreateSegmentMetric(
        segmentId: string,
        dimensionId: string,
        requestBody: CreateSegmentMetricDto,
    ): CancelablePromise<Array<SegmentMetric>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/metric',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'dimensionId': dimensionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param dimensionId
     * @param requestBody
     * @returns SegmentMetric
     * @throws ApiError
     */
    public static segmentControllerCreateSegmentMetrics(
        segmentId: string,
        dimensionId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<SegmentMetric>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/metric/batch',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'dimensionId': dimensionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param category
     * @param dateFrom
     * @param dateTo
     * @param dimension
     * @param filter
     * @param sort
     * @param limit
     * @returns any
     * @throws ApiError
     */
    public static segmentControllerGetSegmentMetricsByCategory(
        segmentId: string,
        category: string,
        dateFrom?: string,
        dateTo?: string,
        dimension?: string,
        filter?: FilterMetricsDTO,
        sort?: string,
        limit?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/metrics/{category}',
            path: {
                'segmentId': segmentId,
                'category': category,
            },
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'dimension': dimension,
                'filter': filter,
                'sort': sort,
                'limit': limit,
            },
        });
    }

    /**
     * @param segmentId
     * @param category
     * @param mainDimensionId
     * @param secondaryDimensionId
     * @param dateFrom
     * @param dateTo
     * @param aggregate
     * @param filter
     * @returns FlatComparedSegmentMetricAndInsightsDto
     * @throws ApiError
     */
    public static segmentControllerGetComparedSegmentMetricsByCategoryAndDimensions(
        segmentId: string,
        category: string,
        mainDimensionId: string,
        secondaryDimensionId: string,
        dateFrom?: string,
        dateTo?: string,
        aggregate?: boolean,
        filter?: FilterMetricsDTO,
    ): CancelablePromise<Array<FlatComparedSegmentMetricAndInsightsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/metrics/{category}/dimensions',
            path: {
                'segmentId': segmentId,
                'category': category,
            },
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'mainDimensionId': mainDimensionId,
                'secondaryDimensionId': secondaryDimensionId,
                'aggregate': aggregate,
                'filter': filter,
            },
        });
    }

    /**
     * @param segmentId
     * @param category
     * @param dateFrom
     * @param dateTo
     * @param groupBy
     * @param groupOn
     * @param filter
     * @param dimension
     * @returns FlatChildrenSegmentMetricAndInsightsDto
     * @throws ApiError
     */
    public static segmentControllerGetChildrenMetricsByCategory(
        segmentId: string,
        category: string,
        dateFrom?: string,
        dateTo?: string,
        groupBy?: string,
        groupOn?: string,
        filter?: FilterMetricsDTO,
        dimension?: string,
    ): CancelablePromise<FlatChildrenSegmentMetricAndInsightsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/children/metrics/{category}',
            path: {
                'segmentId': segmentId,
                'category': category,
            },
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'groupBy': groupBy,
                'groupOn': groupOn,
                'filter': filter,
                'dimension': dimension,
            },
        });
    }

    /**
     * @param segmentId
     * @param category
     * @param mainDimensionId
     * @param secondaryDimensionId
     * @param dateFrom
     * @param dateTo
     * @param groupBy
     * @param groupOn
     * @param filter
     * @returns FlatChildrenComparedSegmentMetricAndInsightsDto
     * @throws ApiError
     */
    public static segmentControllerGetComparedChildrenMetricsByCategory(
        segmentId: string,
        category: string,
        mainDimensionId: string,
        secondaryDimensionId: string,
        dateFrom?: string,
        dateTo?: string,
        groupBy?: string,
        groupOn?: string,
        filter?: FilterMetricsDTO,
    ): CancelablePromise<FlatChildrenComparedSegmentMetricAndInsightsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/children/metrics/{category}/dimensions',
            path: {
                'segmentId': segmentId,
                'category': category,
            },
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'groupBy': groupBy,
                'groupOn': groupOn,
                'mainDimensionId': mainDimensionId,
                'secondaryDimensionId': secondaryDimensionId,
                'filter': filter,
            },
        });
    }

    /**
     * @param segmentId
     * @returns Employee
     * @throws ApiError
     */
    public static segmentControllerGetSegmentEmployees(
        segmentId: string,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/employee',
            path: {
                'segmentId': segmentId,
            },
        });
    }

    /**
     * @param segmentMetricId
     * @param dimensionId
     * @param requestBody
     * @returns SegmentMetricInsight
     * @throws ApiError
     */
    public static segmentControllerCreateSegmentMetricInsight(
        segmentMetricId: string,
        dimensionId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<SegmentMetricInsight>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/metric/{segmentMetricId}/insight',
            path: {
                'segmentMetricId': segmentMetricId,
            },
            query: {
                'dimensionId': dimensionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns SegmentTravelEventDefinition
     * @throws ApiError
     */
    public static segmentControllerAddTravelEventDefinition(
        segmentId: string,
        xCcApiKey: string,
        requestBody: AddSegmentTravelEventDefinitionDto,
    ): CancelablePromise<SegmentTravelEventDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/travel-event-definition',
            path: {
                'segmentId': segmentId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param startDate
     * @param endDate
     * @returns FlatTravelEventDto
     * @throws ApiError
     */
    public static segmentControllerGetTravelEvents(
        segmentId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<FlatTravelEventDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/travel-event',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @param segmentId
     * @param requestBody
     * @returns FlatTravelEventDto
     * @throws ApiError
     */
    public static segmentControllerCreateTravelEvent(
        segmentId: string,
        requestBody: CreateTravelEventDto,
    ): CancelablePromise<FlatTravelEventDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/travel-event',
            path: {
                'segmentId': segmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param startDate
     * @param endDate
     * @returns any
     * @throws ApiError
     */
    public static segmentControllerGetTravelEventsCsv(
        segmentId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/travel-event.csv',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @param requestBody
     * @returns JobGroupIdDto
     * @throws ApiError
     */
    public static segmentControllerRecalculateAll(
        requestBody: RecalculateAllSegmentsDto,
    ): CancelablePromise<JobGroupIdDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/recalculate-all',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static segmentControllerSynchronousRecalculate(
        segmentId: string,
        requestBody: RecalculateSegmentDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/synchronous-recalculate',
            path: {
                'segmentId': segmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param requestBody
     * @returns SegmentEmissionEventDefinition
     * @throws ApiError
     */
    public static segmentControllerAddEmissionEventDefinition(
        segmentId: string,
        requestBody: AddEmissionEventDefinitionDto,
    ): CancelablePromise<SegmentEmissionEventDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/emission-event-definition',
            path: {
                'segmentId': segmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns SegmentEmployeeDefinition
     * @throws ApiError
     */
    public static segmentControllerAddEmployeeDefinition(
        segmentId: string,
        xCcApiKey: string,
        requestBody: AddEmployeeDefinitionDto,
    ): CancelablePromise<SegmentEmployeeDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/segments/{segmentId}/employee-definition',
            path: {
                'segmentId': segmentId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param segmentId
     * @returns SegmentEmployeeDefinition
     * @throws ApiError
     */
    public static segmentControllerSegmentEmployeeDefinition(
        segmentId: string,
    ): CancelablePromise<Array<SegmentEmployeeDefinition>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/employee-definition',
            path: {
                'segmentId': segmentId,
            },
        });
    }

    /**
     * @param segmentId
     * @param startDate
     * @param endDate
     * @returns TeamPerformanceDto
     * @throws ApiError
     */
    public static segmentControllerGetTeamPerformance(
        segmentId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<TeamPerformanceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/team-performance',
            path: {
                'segmentId': segmentId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @param segmentId
     * @returns StatusSummaryDto
     * @throws ApiError
     */
    public static segmentControllerGetSummaryStatus(
        segmentId: string,
    ): CancelablePromise<Array<StatusSummaryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/segments/{segmentId}/status-summary',
            path: {
                'segmentId': segmentId,
            },
        });
    }

    /**
     * @param segmentId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static segmentControllerUpdateSegmentStatus(
        segmentId: string,
        requestBody: UpdateSegmentStatusDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/segments/{segmentId}/status',
            path: {
                'segmentId': segmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
