import { type init } from '@sentry/nextjs';

type SentryConfig = Parameters<typeof init>[0];

// In server and edge runtimes, SENTRY_LOG_ERRORS will be defined.
// In browser, only NEXT_PUBLIC_SENTRY_LOG_ERRORS will be defined.
const logErrorsToConsole =
  (process.env.SENTRY_LOG_ERRORS ?? process.env.NEXT_PUBLIC_SENTRY_LOG_ERRORS) === 'TRUE';

/**
 * Log messages and exceptions to the console for easier debugging.
 *
 * @see https://github.com/getsentry/sentry-javascript/issues/1600
 */
const beforeSend: SentryConfig['beforeSend'] = (event, hint) => {
  if (event.message) {
    console.info(event.message);
  }

  if (hint.originalException) {
    console.error(hint.originalException);
  }

  return event;
};

/**
 * Common Sentry configuration for all runtimes (browser, edge, server)
 */
const dsn = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_CONFIG: SentryConfig = {
  beforeSend: logErrorsToConsole ? beforeSend : undefined,
  dsn,
  enabled: !!dsn,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  tracesSampleRate: 1.0,
};
