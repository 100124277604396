/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddUserRoleDto } from '../models/AddUserRoleDto';
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { CreatePermissionsToRoleDto } from '../models/CreatePermissionsToRoleDto';
import type { CreateRoleDto } from '../models/CreateRoleDto';
import type { InviteUserDto } from '../models/InviteUserDto';
import type { Organization } from '../models/Organization';
import type { OrganizationWithBranding } from '../models/OrganizationWithBranding';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * Retrieves organizations for the authorized user
     * @returns Organization
     * @throws ApiError
     */
    public static organizationsControllerMyOrganizations(): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/me',
        });
    }

    /**
     * Create an organization. Organizations are created in auth0 and saved in the local database and connected to entities with the org_id.
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any The Organization Created in database
     * @returns Organization
     * @throws ApiError
     */
    public static organizationsControllerCreate(
        xCcApiKey: string,
        requestBody: CreateOrganizationDto,
    ): CancelablePromise<any | Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve all Organizations from database that are in auth0.
     * @returns OrganizationWithBranding
     * @throws ApiError
     */
    public static organizationsControllerFindAll(): CancelablePromise<Array<OrganizationWithBranding>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations',
        });
    }

    /**
     * Create a role in auth0
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerCreateRole(
        requestBody: CreateRoleDto,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations/role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a permission in auth0 and associate it with an existing role
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerCreatePermission(
        id: string,
        requestBody: CreatePermissionsToRoleDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations/role/{id}/permissions',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Invite a user to an existing organization and attach roles. The user will get an invitation email
     * @param requestBody
     * @param overrideOrgId Super User Org ID override
     * @returns any The Invitation link and meta data
     * @throws ApiError
     */
    public static organizationsControllerAddUser(
        requestBody: InviteUserDto,
        overrideOrgId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations/user',
            headers: {
                'override-org-id': overrideOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove a user from an organization.
     * @param orgId
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerRemoveUser(
        orgId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/organizations/{orgId}/user/{id}',
            path: {
                'orgId': orgId,
                'id': id,
            },
        });
    }

    /**
     * Deletes a role from auth0
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerRemoveRole(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/organizations/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Attach a role to a user in auth0
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerAddRolesUser(
        id: string,
        requestBody: AddUserRoleDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/organizations/user/{id}/roles',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve roles from auth0
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerGetRoles(): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/roles',
        });
    }

    /**
     * Retrieve a single organization
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerFindOne(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update an Organization on AuthO and the database
     * @param id
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerUpdate(
        id: string,
        xCcApiKey: string,
        requestBody: UpdateOrganizationDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/organizations/{id}',
            path: {
                'id': id,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Not Implemented.
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationsControllerRemove(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }

}
