import createCache, { type StylisPlugin } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';
import { type FC } from 'react';
import { theme } from '@/theme';

const LAYER = '@layer';

/**
 * A Stylis plugin that wraps all root nodes in `@layer layerName {...}`
 *
 * @see https://github.com/emotion-js/emotion/issues/3134
 * @see https://github.com/emotion-js/emotion/blob/%40emotion/cache%4011.11.0/packages/cache/src/stylis-plugins.js
 *
 * @example
 *   // input
 *   a {
 *     color: #444;
 *     @media print { a { color: black; } }
 *   }
 *
 *   // output
 *   @layer myLayer {
 *     a { color: #444; }
 *   }
 *   @layer myLayer {
 *     @media print {
 *       a { color: black; }
 *     }
 *   }
 */
export const wrapInLayer: (layerName: string) => StylisPlugin = (layerName) => (element) => {
  // If we're not at the root of the tree, Stylis will have set a `root`
  // reference. Leave the node intact.
  if (element.root != null) return;

  // Convert the current node to a new child and replace the root with a @layer.
  const child = { ...element, root: element };

  Object.assign(element, {
    children: [child],
    length: LAYER.length,
    parent: null,
    props: [layerName],
    root: null,
    type: LAYER,
    value: `${LAYER} ${layerName}`,
  });
};

const emotionCache = createCache({
  key: 'emotion-css-cache',
  prepend: false,
  stylisPlugins: [wrapInLayer('base.chakra')],
});

/**
 * A wrapper for the out-of-the-box ChakraProvider that puts all the Emotion
 * CSS wrapped in a `@layer chakra` so our own CSS can override Chakra styles
 * in subsequent layers.
 *
 * @see @/css/base.scss
 */
const ChakraProviderWithLayer: FC<ChakraProviderProps> = ({ children, ...rest }) => {
  return (
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme} {...rest}>
        {children}
      </ChakraProvider>
    </CacheProvider>
  );
};

export { ChakraProviderWithLayer as ChakraProvider };
