/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateContentAccessRuleSetDTO = {
    /**
     * ID of the content this rule set grants access to.
     */
    contentId: string;
    /**
     * Access type this rule set grants access to.
     */
    accessType: CreateContentAccessRuleSetDTO.accessType;
    /**
     * Scope of the rule. Defaults to organization.
     */
    scope: CreateContentAccessRuleSetDTO.scope;
    /**
     * ID of the organization this rule set applies to.
     */
    organizationId: string;
};

export namespace CreateContentAccessRuleSetDTO {

    /**
     * Access type this rule set grants access to.
     */
    export enum accessType {
        READ = 'READ',
    }

    /**
     * Scope of the rule. Defaults to organization.
     */
    export enum scope {
        GLOBAL = 'GLOBAL',
        ORGANIZATION = 'ORGANIZATION',
    }


}

