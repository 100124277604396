/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmissionEventDto } from '../models/AddEmissionEventDto';
import type { AddEmployeeMetadataFrameDto } from '../models/AddEmployeeMetadataFrameDto';
import type { AddFullEmployeeMetadata } from '../models/AddFullEmployeeMetadata';
import type { AddManyMetadataDto } from '../models/AddManyMetadataDto';
import type { CreateEmployeeDto } from '../models/CreateEmployeeDto';
import type { EmissionEvent } from '../models/EmissionEvent';
import type { Employee } from '../models/Employee';
import type { EmployeeMetadata } from '../models/EmployeeMetadata';
import type { EmployeeMetadataFrame } from '../models/EmployeeMetadataFrame';
import type { EmployeeSegmentSegments } from '../models/EmployeeSegmentSegments';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static employeeV2ControllerGetEmployees(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/employees',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns Employee
     * @throws ApiError
     */
    public static employeeV2ControllerCreateEmployee(
        xCcApiKey: string,
        requestBody: CreateEmployeeDto,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns Employee
     * @throws ApiError
     */
    public static employeeV2ControllerCreateEmployees(
        xCcApiKey: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/batch',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static employeeV2ControllerUpsertFullEmployeeMetadata(
        requestBody: AddFullEmployeeMetadata,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/full-employee-metadata',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param key
     * @param employeeLookupRangeStartDate The start date to look for the employees
     * @param employeeLookupRangeEndDate The end date to look for the employees
     * @param value
     * @param getEmployeeMetadataKeys The metadata keys to get from the employees
     * @returns Employee
     * @throws ApiError
     */
    public static employeeV2ControllerGetByMetadata(
        key: string,
        employeeLookupRangeStartDate: string,
        employeeLookupRangeEndDate: string,
        value?: string,
        getEmployeeMetadataKeys?: Array<string>,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/employees/metadata',
            query: {
                'key': key,
                'value': value,
                'employeeLookupRangeStartDate': employeeLookupRangeStartDate,
                'employeeLookupRangeEndDate': employeeLookupRangeEndDate,
                'getEmployeeMetadataKeys': getEmployeeMetadataKeys,
            },
        });
    }

    /**
     * @param employeeId
     * @param requestBody
     * @returns EmployeeSegmentSegments
     * @throws ApiError
     */
    public static employeeV2ControllerAddEmployeeToSegment(
        employeeId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<EmployeeSegmentSegments>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/{employeeId}/segment',
            path: {
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param metadataFrameRangeStartDate
     * @param metadataFrameRangeEndDate
     * @returns EmployeeMetadataFrame
     * @throws ApiError
     */
    public static employeeV2ControllerGetEmployeeMetadataFrames(
        employeeId: string,
        metadataFrameRangeStartDate?: string,
        metadataFrameRangeEndDate?: string,
    ): CancelablePromise<Array<EmployeeMetadataFrame>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/employees/{employeeId}/metadata-frame',
            path: {
                'employeeId': employeeId,
            },
            query: {
                'metadataFrameRangeStartDate': metadataFrameRangeStartDate,
                'metadataFrameRangeEndDate': metadataFrameRangeEndDate,
            },
        });
    }

    /**
     * @param employeeId
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns EmployeeMetadataFrame
     * @throws ApiError
     */
    public static employeeV2ControllerAddEmployeeMetadataFrame(
        employeeId: string,
        xCcApiKey: string,
        requestBody: AddEmployeeMetadataFrameDto,
    ): CancelablePromise<EmployeeMetadataFrame> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/{employeeId}/metadata-frame',
            path: {
                'employeeId': employeeId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param metadataFrameId
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns EmployeeMetadata
     * @throws ApiError
     */
    public static employeeV2ControllerAddEmployeeMetadata(
        metadataFrameId: string,
        xCcApiKey: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<EmployeeMetadata>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/{employeeId}/metadata-frame/{metadataFrameId}/metadata',
            path: {
                'metadataFrameId': metadataFrameId,
            },
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param requestBody
     * @returns EmissionEvent
     * @throws ApiError
     */
    public static employeeV2ControllerAddEmissionEvent(
        employeeId: string,
        requestBody: AddEmissionEventDto,
    ): CancelablePromise<EmissionEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/employees/{employeeId}/emission-event',
            path: {
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param emissionEventId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static employeeV2ControllerPatchEmissionEventMetadata(
        employeeId: string,
        emissionEventId: string,
        requestBody: AddManyMetadataDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/employees/{employeeId}/emission-event/{emissionEventId}/metadata',
            path: {
                'employeeId': employeeId,
                'emissionEventId': emissionEventId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param emissionEventId
     * @returns any
     * @throws ApiError
     */
    public static employeeV2ControllerDeleteEmissionEvent(
        employeeId: string,
        emissionEventId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/employees/{employeeId}/emission-event/{emissionEventId}',
            path: {
                'employeeId': employeeId,
                'emissionEventId': emissionEventId,
            },
        });
    }

}
