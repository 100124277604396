/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmissionEventService {

    /**
     * Upserts emission event
     * @param xCcApiKey Authorization key to authenticate controller
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static dataPipelineEmissionEventControllerUploadEmployee(
        xCcApiKey: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/data-pipeline/emission-event',
            headers: {
                'x-cc-api-key': xCcApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
