/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddMetadataDto } from './AddMetadataDto';

export type AddEmissionEventDto = {
    eventDate: string;
    definitiveness: AddEmissionEventDto.definitiveness;
    travelEventId?: string;
    status?: AddEmissionEventDto.status;
    replacedByEventId?: string;
    emissionEventExternalId?: string;
    metadata?: Array<AddMetadataDto>;
};

export namespace AddEmissionEventDto {

    export enum definitiveness {
        ACTUAL = 'actual',
        PLANNED = 'planned',
    }

    export enum status {
        PENDING = 'pending',
        COMPLETED = 'completed',
        REFUNDED = 'refunded',
        EXCHANGED = 'exchanged',
    }


}

