/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContentAccessRule } from './ContentAccessRule';
import type { Organization } from './Organization';

export type ContentAccessRuleSet = {
    /**
     * uuid
     */
    id?: string;
    /**
     * created at
     */
    createdAt?: string;
    /**
     * updated at
     */
    updatedAt?: string;
    /**
     * description
     */
    deletedAt?: string;
    rules: Array<ContentAccessRule>;
    contentId: string;
    accessType: ContentAccessRuleSet.accessType;
    scope: ContentAccessRuleSet.scope;
    organization: Organization;
};

export namespace ContentAccessRuleSet {

    export enum accessType {
        READ = 'READ',
    }

    export enum scope {
        GLOBAL = 'GLOBAL',
        ORGANIZATION = 'ORGANIZATION',
    }


}

